.App {
  text-align: center;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  height: 100vh;
  width: 100%;
  background-color: #000000;
  padding-top: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 8px;
}

.icon-box {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-text {
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  color: #ffffff;
}

.app-bottom {
  height: auto;
  width: 100%;
  background-color: #16181D;
  text-align: start;

  div {
    padding: 24px 16px 54px 16px;
  }

  span {
    color: #7A8396;
    font-size: 12px;
  }

  .foot-text {
    font-weight: 400;
  }

  .foot-text2 {
    font-weight: 600;
  }
}

.button-box {
  min-width: 313px;
  min-height: 54px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .button-log {
    width: 20px;
    height: 24px;
  }

  .button-text {
    width: 85px;
    margin-left: 6px;
    padding-top: 5px
  }
}